import { Controller } from "@hotwired/stimulus"

export default class AccordionController extends Controller {
  static targets = ["item"]

  connect() {
    this.itemTargets.forEach((item) => {
      item.addEventListener("mouseover", () => this.highlight(item))
      item.addEventListener("mouseout", () => this.resetNormalOpacity())
    })
  }

  highlight(currentItem) {
    this.itemTargets.forEach((item) => {
      if (item !== currentItem) {
        item.classList.add("opacity-40")
      }
    })
  }

  resetNormalOpacity() {
    this.itemTargets.forEach((item) => {
      item.classList.remove("opacity-40")
    })
  }

  toggleCollapseContent(event) {
    const item = event.currentTarget
    const cross = item.querySelector("[data-collapsible-cross]")

    if (cross)
      cross.classList.toggle("rotate-135")

    const text = item.querySelector("[data-collapsible-content]")
    text.classList.toggle("py-5")
    text.classList.toggle("max-h-0")
    text.classList.toggle("h-screen")
    text.classList.toggle("min-h-fit")
    text.classList.toggle("max-h-fit")
    text.classList.toggle("border-t")
  }
}
